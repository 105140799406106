import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {catchError, filter, map, Observable, throwError} from 'rxjs';
import * as console from "console";
import {SpinnerServiceService} from "./services/spinner-service.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import * as _ from 'lodash';

@Injectable()
export class SelfBillingInvoiceInterceptorInterceptor implements HttpInterceptor {
  private token: string = 'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJQUklZQVFBQURNSU4iLCJzdWIiOiJ2YXQiLCJpc3MiOiJvbnl4LXZhdCIsImlhdCI6MTcxOTQxODYyMywiZXhwIjoxNzE5NTkxNDIzLCJob3RlbEluZm8iOnsiY2hhaW5zIjpbeyJjaGFpbklkIjoiUlQiLCJicmFuZHMiOlt7ImJyYW5kSWQiOiJBRCIsInByb3BlcnRpZXMiOlt7InByb3BlcnR5SWQiOiIxOTEwIn0seyJwcm9wZXJ0eUlkIjoiMjgxNiJ9XSwiYWxsUHJvcGVydGllcyI6WyIxOTEwIiwiMjgxNiJdfSx7ImJyYW5kSWQiOiJBOSIsInByb3BlcnRpZXMiOlt7InByb3BlcnRpZUlEIjoiQTYwOSJ9LHsicHJvcGVydHlJZCI6IjkyMjEifSx7InByb3BlcnRpZUlEIjoiOTIyMiJ9LHsicHJvcGVydHlJZCI6IkIzSDMifV0sImFsbFByb3BlcnRpZXMiOlsiQTYwOSIsIjkyMjEiLCJCM0gzIiwiOTIyMiJdfV0sImFsbFByb3BlcnRpZXMiOlsiMTkxMCIsIkE2MDkiLCI5MjIxIiwiQjNIMyIsIjkyMjIiLCIyODE2Il0sImFsbENoYWlucyI6WyJSVCJdLCJhbGxCcmFuZHMiOlsiQUQiLCJBOSJdfV0sIm5iZiI6MTcxOTQxODMyMywicm9sZSI6ImhvdGVsIiwiZW1haWwiOiJQUklZQS5LQVJOSUtAT05ZWENFTlRFUlNPVVJDRS5DT00iLCJmaXJzdG5hbWUiOiJQUklZQSIsImxhc3RuYW1lIjoiS0FSTklLIiwiYXBwIjoiUE9SVEFMIiwidXNlck5hbWUiOiJQUklZQVFBRG1pbiIsIlNTTyI6ZmFsc2UsImNzUm9sZSI6IkhPVEVMMyIsImJhY2tMaW5rIjp7InRpdGxlIjoiT255eCBDZW50ZXJTb3VyY2UgUG9ydGFsIiwidXJsIjoiaHR0cDovL2xvY2FsaG9zdDo4MDgwL3BvcnRhbC0yMDI0MDYyNjE0NTUvIn19.RDw2wcEolnWIWpy4RohoD91PPBSZtJ0rUNimPlt24KF4EHCtF6QJ3buRW0dVosmwZ0QTsP-hHKXAj9SOIi3bcA';

  private queryParams: any = {};
  constructor(private spinnerService: SpinnerServiceService,
              private route: ActivatedRoute,
              private router: Router) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.route.queryParams.subscribe(params => {
        this.queryParams = params;
      });
    });
  }


  private handleError(error: HttpErrorResponse) {
    this.spinnerService.setValue(false);
    if (error.status === 400) {
      this.router.navigate(['error', 400]);
      return throwError(() => error);
    } else {
      this.router.navigate(['error', 401]);
      return throwError(() => new Error('An error occurred during Authorization:'));
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request and set the new header
    this.spinnerService.setValue(true);
    let token = this.getLatestToken(req);
    const authReq = req.clone({setHeaders: {Authorization: token}});

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(authReq)
      .pipe(
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            return this.handleError(err);
          } else {
            return throwError(err.message);
          }
        })
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            const headers = evt.headers;
            if (headers.get('x-token') != null) {
              const newToken = headers.get('x-token');
              localStorage.setItem('Authorization', newToken);
            }
          }
          this.spinnerService.setValue(false);
          return evt;
        })
      );
  }

  private getTokenFromLocalStorage() {
    let localStorageToken = localStorage.getItem('Authorization');
    return localStorageToken;
  }

  private getTokenFromUrl(req: HttpRequest<any>) {
    let authHeader = req.headers.get('Authorization');
    return authHeader;
  }

  private getLatestToken(req: HttpRequest<any>): string | null {
    let localStorageToken = this.getTokenFromLocalStorage();
    if (!_.isNil(localStorageToken)) {
      return localStorageToken;
    }
    return null;
  }
}
