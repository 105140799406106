import {Injectable} from '@angular/core';
import {Router, Resolve, ActivatedRouteSnapshot} from '@angular/router';
import {EMPTY, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import * as _ from 'lodash';
import {SecurityUserResponse} from "../../models/security-user-response.model";
import {InvoiceService} from "../../services/invoice.service";

@Injectable({
  providedIn: 'root',
})
export class SecureResolver implements Resolve<SecurityUserResponse> {

  constructor(
    private router: Router,
    private invoiceService: InvoiceService
  ) {
  }


  resolve(route: ActivatedRouteSnapshot): Observable<SecurityUserResponse> {
    const latestAuthToken = this.getLatestToken(route);
    console.log("Latest token : " , latestAuthToken);
    if (!_.isNil(latestAuthToken)) {
      localStorage.setItem('Authorization', latestAuthToken);
    }
    return this.invoiceService.getToken().pipe(
      catchError((error) => {
        console.log(error);
        this.router.navigate(['error']);
        return EMPTY;
      })
    );
  }


  private getLatestToken(route: ActivatedRouteSnapshot): string | null {
    let paramsToken = null;
    console.log(route.queryParams['authorization']);
    if (!!route.queryParams && !!route.queryParams['authorization']) {
      paramsToken = route.queryParams['authorization'] || null;

    } else if (route.paramMap.get('authorization')) {
      paramsToken = route.paramMap.get('authorization') || null;
    }
    if (!_.isNil(paramsToken)) {
      return paramsToken;
    }
    return null;
  }

}
